<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <Form
        :category-data.sync="formData"
        :root-categories="rootCategories"
        @formSubmit="doFormSave"
      /></div>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BSpinner } from 'bootstrap-vue'
import _ from 'lodash'
import store from '@/store'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  name: '',
  slug: '',
  parent_id: null,
})

export default {
  name: 'CategoryUpdate',
  components: {
    BCard,
    BSpinner,
    Form,
  },
  data() {
    return {
      formData: { ...emptyFormData },
      categoryData: null,
      rootCategories: null,
      loading: true,
    }
  },
  mounted() {
    Promise.all([
      this.loadCategoryInfo(),
      this.loadRootCategories(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    doFormSave() {
      console.log(this.formData)
    },
    loadCategoryInfo() {
      const req = axiosIns({
        method: 'GET',
        url: `/categories/show/${this.$route.params.id}`,
      })

      req.then(response => {
        this.categoryData = response.data.data
        this.formData = _.merge(this.formData, response.data.data)
        store.commit('app/UPDATE_CURRENT_PAGE_TITLE', this.formData.name)
      })

      return req
    },
    loadRootCategories() {
      const req = axiosIns({
        method: 'GET',
        url: '/categories/getAllRoot',
      })

      req.then(response => {
        const defaultCategories = [{ id: null, name: '' }]
        defaultCategories.push(...response.data.data)
        this.rootCategories = defaultCategories
      })

      return req
    },
  },
}
</script>
